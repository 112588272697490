
@media (max-width: 320px) {
  .form-wrapper {
    margin-bottom: 54rem;
  }
}

@media (min-width: 320px) and (max-width: 425px) {
  .form-wrapper {
    margin-bottom: 44rem;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .form-wrapper {
    margin-bottom: 24rem;
  }
}

/* @media (min-width:1440px) {
    .form-wrapper {
        margin-bottom: 24rem;
    }
} */
